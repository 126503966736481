import notify from 'devextreme/ui/notify'

export default function toast(message, type, time) {
  notify({
    message,
    type: type ?? 'info',
    position: {
      my: 'top right',
      at: 'top right',
      of: '.main-window',
      offset: '-24 24',
    },
    maxWidth: '84vw',
    minWidth: '180px',
    width: 568,
    displayTime: time ?? 2000,
  })
}
