import CustomStore from 'devextreme/data/custom_store'
import { gql } from '@apollo/client'
import { useAuthAwareQuery } from '../../../api/apiClient'

export default function GovernmentJobFunctionSource(props) {
  const getGovernmentJobFunctions = gql`
    query GetGovernmentJobFunctions {
      government_job_functions {
        items {
          id
          name
        }
      }
    }
  `

  const { loading, error, data } = useAuthAwareQuery(getGovernmentJobFunctions)

  return new CustomStore({
    key: 'id',
    loadMode: 'raw',

    isLoading: () => {
      return loading
    },

    load: () => {
      if (error) {
        return []
      }

      if (data) {
        return data.government_job_functions.items
      }
    },
  })
}
