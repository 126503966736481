import React from 'react'
import UserProfileForm from '../pages/settings/profile/UserProfileForm'
import ValidateEmailForm from '../pages/settings/profile/ValidateEmailForm'
import { useAuth } from '../contexts/auth'

export default function Welcome(props) {
  const { user } = useAuth()

  return (
    <div className="flex h-screen flex-row overflow-y-auto bg-gray-50">
      <div
        className="mx-auto py-8 px-6 lg:pt-12"
        style={{ flex: '0 1 1200px' }}
      >
        {!user.profile.minimum_requirements_met && <UserProfileForm />}

        {user.profile.minimum_requirements_met && <ValidateEmailForm />}
      </div>
    </div>
  )
}
