export async function graphQuery(query, variables = null) {
  let body = {
    query: query.replace(/\s\s+/g, ' '),
  }
  if (variables !== null) {
    if (variables.filter !== undefined) {
      variables.filter = JSON.stringify(variables.filter)
    }
    body.variables = variables
  }

  return fetch('/graphql/isp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((result) => {
      if (result.data === undefined) {
        throw Error()
      }
      return result.data
    })
    .catch(() => {
      throw new Error(
        'Unable to complete action. Please check your input and try again.'
      )
    })
}
