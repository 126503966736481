import React, { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useAuthAwareMutation } from '../../../api/apiClient'
import SaveIndicator from '../../../components/widgets/saveIndicator'

export default function RequestEmailValidationLink(props) {
  const [success, setSuccess] = useState(false)

  const verificationLinkMutation = gql`
    mutation RequestEmailVerificationLink {
      requestEmailVerificationLink {
        errors
        entity {
          minimum_requirements_met
        }
      }
    }
  `

  const [requestLink, { loading, error, data }] = useAuthAwareMutation(
    verificationLinkMutation
  )

  useEffect(() => {
    if (error || data) {
      if (
        error ||
        data.errors ||
        data.requestEmailVerificationLink.entity === null
      ) {
        // @todo: handle errors
      } else {
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 2000)
      }
    }
  }, [data, error])

  const requestVerificationLink = (e) => {
    requestLink()
  }

  return (
    <div className="flex flex-row items-center pt-6">
      <button
        className="mr-4 inline-flex justify-center rounded-md border border-transparent bg-teal-300 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2"
        onClick={requestVerificationLink}
        disabled={loading || success}
        title="Request another email verification link"
      >
        Resend
      </button>
      <SaveIndicator loading={loading} saved={success} />
    </div>
  )
}
