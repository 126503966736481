import React, { useEffect, useRef, useState } from 'react'
import { gql, makeVar, useReactiveVar } from '@apollo/client'
import { useAuthAwareMutation, useAuthAwareQuery } from '../../../api/apiClient'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import Autocomplete from 'devextreme-react/autocomplete'
import TextBox from 'devextreme-react/text-box'
import { RequiredRule, Validator } from 'devextreme-react/validator'
import ProfileCountryWidget from './ProfileCountryWidget'
import ValidationGroup from 'devextreme-react/validation-group'
import ValidationSummary from 'devextreme-react/validation-summary'
import RequestEmailChange from './RequestEmailChange'
import IndustryJobFunction from './IndustryJobFunction'
import GovernmentJobFunction from './GovernmentJobFunction'
import { useAuth } from '../../../contexts/auth'
import { classNames } from '../../../utils/headlessUiHelpers'
import SaveIndicator from '../../../components/widgets/saveIndicator'
import { cache } from '../../../api/cache'

export const profileEditValuesVar = makeVar(null)

export default function UserProfileForm() {
  const { user } = useAuth()

  const welcomeVersion = !user.profile.minimum_requirements_met

  const profileValidationGroup = useRef()

  const [profile, setProfile] = useState({})
  const profileEditValues = useReactiveVar(profileEditValuesVar)
  const [saved, setSaved] = useState(false)
  const [valid, setValid] = useState(true)

  const getProfile = gql`
    query GetUserProfile {
      me {
        id
        email
        work_sector
        profile {
          id
          salutation
          first_name
          last_name
          organisation
          job_title
          phone
          minimum_requirements_met
          job_function {
            ... on GovernmentJobFunction {
              id
              name
            }
            ... on IndustryJobFunction {
              id
              name
            }
          }
          location {
            country_code
            administrative_area
          }
        }
      }
    }
  `

  const {
    loading: profileLoading,
    error: profileError,
    data: profileData,
  } = useAuthAwareQuery(getProfile)

  const validateProfileMutation = gql`
    mutation ValidateUserProfile($data: UserProfileUpdate) {
      updateCurrentUserProfile(data: $data) {
        errors
        entity {
          minimum_requirements_met
        }
      }
    }
  `
  const [updateProfile, { loading, error, data }] = useAuthAwareMutation(
    validateProfileMutation
  )

  /**
   * Load profile data into state.
   */
  useEffect(() => {
    if (profileData !== undefined) {
      setProfile(profileData.me.profile)
    }
  }, [profileData])

  /**
   * Save animation timing.
   */
  useEffect(() => {
    if (error || data) {
      if (error || data.errors) {
        // @todo: handle errors
      } else {
        setSaved(true)
        cache.evict({ fieldName: 'me' })
        setTimeout(() => {
          setSaved(false)
        }, 2000)
      }
    }
  }, [data, error])

  /**
   * Save after all edits have paused for 2 seconds to avoid race conditions on updates.
   */
  useEffect(() => {
    if (welcomeVersion) {
      return
    }
    if (profileEditValues !== null) {
      const result = profileValidationGroup.current.instance.validate()
      setValid(result.isValid)
      if (result.isValid) {
        const handler = setTimeout(() => {
          updateProfile({ variables: { data: profileEditValues } })
          profileEditValuesVar(null)
        }, 2000)
        return () => clearTimeout(handler) // Debounce.
      }
    }
  }, [profileEditValues, updateProfile, welcomeVersion])

  /**
   * LOADING
   */
  if (profileLoading) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <LoadIndicator />
      </div>
    )
  }

  /**
   * ERROR
   */
  if (profileError) {
    return <>{profileError}</>
  }

  /**
   * State changes.
   */
  const onValueChanged = (key, value) => {
    if (profile[key] !== value) {
      profileEditValuesVar({ ...profileEditValues, [key]: value })
    }
    setProfile({ ...profile, [key]: value })
  }

  /**
   * Welcome version submit handler.
   */
  const validateProfile = () => {
    const result = profileValidationGroup.current.instance.validate()
    setValid(result.isValid)
    if (result.isValid) {
      updateProfile({
        variables: {
          data: { ...profileEditValues, minimum_requirements_met: true },
        },
      }).then(() => {
        if (!user.profile.minimum_requirements_met) {
          window.location.reload()
        }
      })
    }
  }

  /**
   * FORM
   */
  const workSector = profileData.me.work_sector

  const organisationLabel =
    workSector === 'government' ? 'Agency' : 'Organisation'

  const submitHandler = (e) => {
    e.preventDefault()
  }

  return (
    <div className="w-full">
      <h1 className="font-heading text-5xl text-gray-900">
        {welcomeVersion ? 'Welcome to Intermedium' : 'Profile'}
      </h1>

      {welcomeVersion && (
        <div className="mt-8">
          <p>
            Please fill out your profile to activate your Intermedium account.
          </p>
        </div>
      )}

      <h2 className="mt-8 text-xl font-medium">Email</h2>
      <p className="mt-2 text-sm text-slate-500">
        Your profile email address is <strong>{profileData.me.email}</strong>
      </p>

      {!welcomeVersion && (
        <>
          {workSector === 'public' && (
            <div className="mt-8 rounded-r-md border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <h3 className="text-sm font-medium text-yellow-800">
                Please note:
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Personal email addresses cannot be assigned to enterprise
                  subscriptions.
                </p>
                <p>
                  If your organisation holds an Intermedium subscription please
                  use your professional email address.
                </p>
              </div>
            </div>
          )}

          <div className="mt-8">
            <div className="sm:col-span-6">
              <h3 className="text-md font-medium">Change address</h3>
              <p className="mt-1 text-sm text-slate-500">
                Entering a new email here will send a link to the new address
                that completes the change - as long as the address is not
                already in use.
              </p>
            </div>

            <RequestEmailChange />
          </div>
        </>
      )}

      <hr className="mt-8" />
      <ValidationGroup
        ref={profileValidationGroup}
        className="divide-y-slate-200 my-8 space-y-8 divide-y"
      >
        <form onSubmit={submitHandler}>
          <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="text-xl font-medium">Personal details</h2>
              <p className="mt-1 text-sm text-slate-500">
                This information helps our staff support you, please refer to
                our{' '}
                <a
                  className="font-bold text-teal-300 hover:text-teal-600"
                  href="https://www.intermedium.com.au/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                for details on how we handle your private information.
              </p>
            </div>

            <div className="sm:col-span-2">
              <div className="rounded bg-white">
                <Autocomplete
                  items={['Mr', 'Mrs', 'Ms', 'Miss', 'Mx']}
                  label="Salutation"
                  name="salutation"
                  onValueChanged={(e) => {
                    onValueChanged('salutation', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.salutation}
                />
              </div>
            </div>

            <div className="sm:col-span-3 sm:col-start-1">
              <div className="rounded bg-white">
                <TextBox
                  label="First name *"
                  name="first-name"
                  onValueChanged={(e) => {
                    onValueChanged('first_name', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.first_name}
                >
                  <Validator>
                    <RequiredRule message="First name is required" />
                  </Validator>
                </TextBox>
              </div>
            </div>

            <div className="sm:col-span-3">
              <div className="rounded bg-white">
                <TextBox
                  label="Last name *"
                  name="last-name"
                  onValueChanged={(e) => {
                    onValueChanged('last_name', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.last_name}
                >
                  <Validator>
                    <RequiredRule message="Last name is required" />
                  </Validator>
                </TextBox>
              </div>
            </div>

            <div className="sm:col-span-3 sm:col-start-1">
              <div className="rounded bg-white">
                <TextBox
                  label="Phone number *"
                  mode="tel"
                  name="phone"
                  onValueChanged={(e) => {
                    onValueChanged('phone', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.phone}
                >
                  <Validator>
                    <RequiredRule message="Phone number is required" />
                  </Validator>
                </TextBox>
              </div>
            </div>

            <ProfileCountryWidget location={profile.location} />
          </div>

          <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-6 sm:gap-x-6">
            <div className="sm:col-span-6">
              <h2 className="mt-8 text-xl font-medium">
                {workSector === 'government' ? 'Agency' : 'Corporate'} details
              </h2>
              <p className="mt-1 text-sm text-slate-500">
                Help us quickly match you with your enterprise subscription.
              </p>
            </div>
            <div
              className={
                workSector === 'government' ? 'sm:col-span-6' : 'sm:col-span-3'
              }
            >
              <div className="rounded bg-white">
                <TextBox
                  label={`${organisationLabel} *`}
                  name="organisation"
                  onValueChanged={(e) => {
                    onValueChanged('organisation', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.organisation}
                >
                  <Validator>
                    <RequiredRule
                      message={`${organisationLabel} is required`}
                    />
                  </Validator>
                </TextBox>
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="rounded bg-white">
                <TextBox
                  label="Job title *"
                  name="job-title"
                  onValueChanged={(e) => {
                    onValueChanged('job_title', e.value)
                  }}
                  stylingMode="outlined"
                  value={profile.job_title}
                >
                  <Validator>
                    <RequiredRule message="Job title is required" />
                  </Validator>
                </TextBox>
              </div>
            </div>
            {workSector === 'government' && (
              <GovernmentJobFunction
                jobFunction={
                  profile.job_function ? profile.job_function.id : null
                }
              />
            )}
            {workSector !== 'government' && (
              <IndustryJobFunction
                jobFunction={
                  profile.job_function ? profile.job_function.id : null
                }
              />
            )}
          </div>

          {welcomeVersion && (
            <div className="flex flex-col justify-between pt-8 sm:flex-row">
              <ValidationSummary />
              <div className="flex flex-row">
                <SaveIndicator loading={loading} saved={saved} />
                <button
                  className="ml-4 inline-flex justify-center self-end rounded-md border border-transparent bg-teal-300 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2 sm:mt-0 sm:self-start"
                  disabled={loading}
                  onClick={validateProfile}
                >
                  Submit
                </button>
              </div>
            </div>
          )}

          {!welcomeVersion && (
            <>
              <div
                className={classNames(
                  !valid ? 'block' : 'hidden',
                  'min-w-40 fixed top-1/2 left-1/2 z-20 -translate-x-1/2 rounded bg-slate-200 px-8 py-6 shadow-lg'
                )}
              >
                <span className="mb-2 block">Cannot save profile:</span>
                <ValidationSummary />
                <button
                  className="mt-4 inline-flex justify-center self-end rounded-md border border-transparent bg-teal-300 py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2 sm:self-start"
                  onClick={() => {
                    setValid(true)
                  }}
                >
                  Ok
                </button>
              </div>
              <div
                className={classNames(
                  loading || saved ? 'block' : 'hidden',
                  'min-w-40 fixed top-1/2 left-1/2 z-20 flex -translate-x-1/2 items-center justify-start rounded bg-slate-200 px-8 py-6 shadow-lg'
                )}
              >
                <SaveIndicator loading={loading} saved={saved} />
                <div className="ml-4">
                  {loading && <span>Saving</span>}
                  {saved && <span>Saved</span>}
                </div>
              </div>
            </>
          )}
        </form>
      </ValidationGroup>
    </div>
  )
}
