import React from 'react'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { Transition } from '@headlessui/react'
import { ReactComponent as Check } from '../../images/check-circle-animated.svg'

export default function SaveIndicator(props) {
  return (
    <div className="h-6 w-6 flex-shrink-0">
      <div className={props.loading ? 'block' : 'hidden'}>
        <LoadIndicator className="max-h-6 max-w-6" />
      </div>

      <Transition
        show={props.saved}
        as={'div'}
        enter={`transition-opacity ease-linear duration-`}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave={`transition-opacity ease-linear duration-1000`}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Check className="max-h-6 max-w-6 text-teal-300" />
      </Transition>
    </div>
  )
}
