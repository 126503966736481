import React, { useState } from 'react'
import { Lookup } from 'devextreme-react/lookup'
import GovernmentJobFunctionSource from './GovernmentJobFunctionSource'
import { RequiredRule, Validator } from 'devextreme-react/validator'
import { profileEditValuesVar } from './UserProfileForm'
import { useReactiveVar } from '@apollo/client'

export default function GovernmentJobFunction(props) {
  const [selected, setSelected] = useState(props.jobFunction)
  const profileEditValues = useReactiveVar(profileEditValuesVar)

  const onValueChanged = (e) => {
    setSelected(e.value)
    profileEditValuesVar({ ...profileEditValues, job_function: e.value })
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="govt-job-function"
        className="mb-1 block text-sm font-medium text-slate-900"
      >
        My work is best described as *
      </label>

      <div className="relative rounded-md bg-white">
        <Lookup
          dataSource={GovernmentJobFunctionSource()}
          displayExpr="name"
          id="govt-job-function"
          name="govt-job-function"
          onValueChanged={onValueChanged}
          stylingMode="outlined"
          value={selected}
          valueExpr="id"
        >
          <Validator>
            <RequiredRule message="Work description is required" />
          </Validator>
        </Lookup>
      </div>
    </div>
  )
}
