import SideNav from './SideNav'
import MobileNav from './MobileNav'
import React, { useState } from 'react'
import Header from './Header'

export default function NavLayout(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <SideNav />

      <MobileNav
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />

      <div className="flex flex-1 flex-col overflow-hidden">
        <Header setMobileMenuOpen={setMobileMenuOpen} />

        <div className="main-window z-0 flex flex-1 items-stretch overflow-hidden">
          <main className="flex-1">{props.children}</main>
        </div>
      </div>
    </div>
  )
}
