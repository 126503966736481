import { Fragment, useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ReactComponent as NavigationMenu } from '../icons/streamline/navigation-menu.svg'
import { ReactComponent as SingleNeutralCircle } from '../icons/streamline/single-neutral-circle.svg'
import { useAuth } from '../contexts/auth'
import { useNavigate } from 'react-router-dom'

export default function Header({ setMobileMenuOpen }) {
  const navigate = useNavigate()
  const { signOut } = useAuth()

  const userMenuItems = useMemo(
    () => [
      {
        text: 'Profile',
        onClick: () => {
          navigate('/settings/profile')
        },
      },
      {
        text: 'Help',
        onClick: () => {
          window.open(
            'mailto:accountsupport@intermedium.com.au?subject=Please%20help%20me%20with%20the%20beta'
          )
        },
      },
      {
        text: 'Log out',
        onClick: signOut,
      },
    ],
    [navigate, signOut]
  )

  return (
    <header className="w-full">
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-inset focus:ring-indigo-500 focus-visible:ring-2 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <NavigationMenu className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-end px-4 sm:px-6">
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            {/* Profile dropdown */}
            <Menu as="div" className="relative z-30 flex-shrink-0">
              {({ open }) => (
                <>
                  <Menu.Button className="flex rounded-full bg-white text-sm text-gray-600 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus-visible:ring-2">
                    <span className="sr-only">Open user menu</span>
                    <SingleNeutralCircle className="h-8 w-8 rounded-full" />
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute right-0 z-30 mt-2 flex w-48 origin-top-right flex-col rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {userMenuItems.map((item, index) => {
                        return (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <button
                                className={`${
                                  active ? 'bg-gray-100' : ''
                                } block flex px-4 py-2 text-sm text-gray-700`}
                                onClick={item.onClick}
                              >
                                {item.text}
                              </button>
                            )}
                          </Menu.Item>
                        )
                      })}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </header>
  )
}
