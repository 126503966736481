import { InMemoryCache } from '@apollo/client'

const mergeItemsById = (
  existing: any[],
  incoming: any[],
  { readField, mergeObjects }
) => {
  const merged: any[] = existing ? existing.slice(0) : []
  const itemIdToIndex: Record<string, number> = Object.create(null)
  if (existing) {
    existing.forEach((item, index) => {
      itemIdToIndex[readField('id', item)] = index
    })
  }
  incoming.forEach((item) => {
    const id = readField('id', item)
    const index = itemIdToIndex[id]
    if (typeof index === 'number') {
      // Merge the new item data with the existing item data.
      merged[index] = mergeObjects(merged[index], item)
    } else {
      // First time we've seen this item in this array.
      itemIdToIndex[id] = merged.length
      merged.push(item)
    }
  })
  return merged
}

// const mergeArrays = (existing = [], incoming: any[]) => {
//   return [...existing, ...incoming];
// }

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        articles: {
          keyArgs: ['keywords', 'sort', 'filter', 'facets'],
        },
        publications: {
          keyArgs: ['keywords', 'sort', 'filter', 'facets'],
        },
        tenders: {
          keyArgs: ['keywords', 'sort', 'filter', 'facets'],
        },
      },
    },
    ArticleConnection: {
      fields: {
        items: {
          merge: mergeItemsById,
          keyArgs: ['skip', 'take'],
        },
      },
    },
    Facets: {
      keyFields: null,
    },
    GovernmentEntityFacet: {
      keyFields: null,
    },
    GovFactFutureICT: {
      keyFields: ['id', 'vid'],
    },
    GovFactICTEnvironment: {
      keyFields: ['id', 'vid'],
    },
    PublicationConnection: {
      fields: {
        items: {
          merge: mergeItemsById,
          keyArgs: ['skip', 'take'],
        },
      },
    },
    TenderConnection: {
      fields: {
        items: {
          merge: mergeItemsById,
          keyArgs: ['skip', 'take'],
        },
      },
    },
  },
})
