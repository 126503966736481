import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'
import mixpanel from 'mixpanel-browser'
import { useAuth } from './auth'
import { useLocation } from 'react-router-dom'

const MixpanelContext = createContext({})
const useMixpanel = () => useContext(MixpanelContext)

function MixpanelProvider(props) {
  const { user } = useAuth()
  let location = useLocation()

  const [product, setProduct] = useState('App')

  useEffect(() => {
    if (user) {
      let token = 'bcf939096ddb46d7a66404f0c02fcfd1' // Development project token
      let debug = true

      if (
        window.location.hostname === 'app.stg.intermedium.com.au' ||
        window.location.hostname === 'beta.stg.intermedium.com.au'
      ) {
        token = '5312d82486ff3a1c1123a62ef8d18017' // Staging project token
      }
      if (
        window.location.hostname === 'app.intermedium.com.au' ||
        window.location.hostname === 'beta.intermedium.com.au'
      ) {
        token = '856f3d04d7bb6f9e5051729efc11939a' // Production project token
        debug = false
      }
      mixpanel.init(token, { debug, api_host: 'https://t.intermedium.com.au' })
      mixpanel.identify(user.id)
    }
  }, [user])

  const track = useCallback(
    async (event_name, properties, options, callback) => {
      properties['Context'] = 'Subscriber Portal'
      properties['Product'] = product
      if (user) {
        properties['Client'] = user.client_name
        properties['Organisation'] = user.profile.organisation
        properties['Package'] = user.package_name
      }
      mixpanel.track(event_name, properties, options, callback)
    },
    [product, user]
  )

  useEffect(() => {
    const subfolder = location.pathname.split('/', 2)
    switch (subfolder[1]) {
      case 'articles':
        setProduct('Articles')
        break
      case 'tenders':
        setProduct('Tenders')
        break
      case 'govfacts':
        setProduct('GovFacts')
        break
      case 'publications':
        setProduct('Publications')
        break
      default:
        setProduct('App')
    }
    track('Page view', {
      Path: location.pathname,
    })
  }, [location, setProduct, track])

  return <MixpanelContext.Provider value={{ track }} {...props} />
}

export { MixpanelProvider, useMixpanel }
