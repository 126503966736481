import CustomStore from 'devextreme/data/custom_store'
import { gql } from '@apollo/client'
import { useAuthAwareQuery } from '../../../api/apiClient'

export default function AddressSubdivisionSource(props) {
  const getAddressCountries = gql`
    query GetAddressCountry($country_code: String!) {
      address_country(country_code: $country_code) {
        subdivisions {
          code
          name
        }
      }
    }
  `

  const { loading, error, data } = useAuthAwareQuery(getAddressCountries, {
    variables: { country_code: props.country_code ?? ' ' },
  })

  return new CustomStore({
    key: 'code',
    loadMode: 'raw',

    isLoading: () => {
      return loading
    },

    load: () => {
      if (error) {
        return []
      }

      if (data) {
        if (props.country_code === 'AU') {
          // Special case for our home country: remove Jervis Bay Territory from the list.
          return data.address_country.subdivisions.filter(
            (x) => x.code !== 'JBT'
          )
        }
        return data.address_country.subdivisions
      }
    },
  })
}
