import { ReactComponent as Logo } from '../images/intermedium-logo-mini.svg'
import NavItems from './NavItems'

export default function SideNav() {
  return (
    <div className="hidden w-28 overflow-y-auto bg-gradient-to-br from-violet-1000 to-violet-600 md:block">
      <div className="flex w-full flex-col items-center py-6">
        <div className="flex flex-shrink-0 items-center">
          <Logo className="h-8 w-auto" />
        </div>
        <div className="mt-6 w-full flex-1 space-y-1 px-2">
          <NavItems />
        </div>
      </div>
    </div>
  )
}
