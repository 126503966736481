import CustomStore from 'devextreme/data/custom_store'
import { gql } from '@apollo/client'
import { useAuthAwareQuery } from '../../../api/apiClient'

export default function AddressCountrySource(props) {
  const getAddressCountries = gql`
    query GetAddressCountries {
      address_countries {
        country_code
        name
        administrative_area_type
      }
    }
  `

  const { loading, error, data } = useAuthAwareQuery(getAddressCountries)

  return new CustomStore({
    key: 'country_code',
    loadMode: 'raw',

    isLoading: () => {
      return loading
    },

    load: () => {
      if (error) {
        return []
      }

      if (data) {
        return data.address_countries
      }
    },
  })
}
