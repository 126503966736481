import React from 'react'
import { Dialog } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as NavigationLeftCircle1 } from '../icons/streamline/navigation-left-circle-1.svg'
import { classNames } from '../utils/headlessUiHelpers'
import { makeVar, useReactiveVar } from '@apollo/client'

export const overlayContentVar = makeVar(null)
export const overlayOptionsVar = makeVar({})

export default function Overlay() {
  const navigate = useNavigate()
  const overlayContent = useReactiveVar(overlayContentVar)
  const overlayOptions = useReactiveVar(overlayOptionsVar)

  const closeOverlay = () => {
    overlayOptions.backPath && navigate(overlayOptions.backPath)
    overlayOptionsVar({})
    overlayContentVar(null)
  }

  return (
    <Dialog
      open={overlayContent !== null}
      onClose={closeOverlay}
      className="absolute inset-0 z-10 overflow-y-auto print:bottom-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-70" />

      <div className="print:hidden">
        <button
          className="fixed z-20 z-20 flex translate-x-4 translate-y-4 transform items-center rounded-md border border-gray-300 bg-white py-2 pl-2 pr-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:translate-x-8 sm:translate-y-8"
          onClick={closeOverlay}
        >
          <NavigationLeftCircle1 className="mr-2 w-5" />
          Close
        </button>
      </div>

      <div
        className={classNames(
          overlayOptions.wide ? 'w-1400 ' : 'w-800 xl:mt-8',
          'relative z-20 mx-auto mt-18 max-h-full max-w-full px-2 print:p-0 sm:mt-24 md:px-4'
        )}
      >
        {(overlayOptions.raw && overlayContent) || (
          <div className="pb-4 md:pb-8">
            <article className="max-w-full rounded bg-white p-4 shadow-sm print:p-0 print:shadow-none sm:p-8">
              {overlayContent}
            </article>
          </div>
        )}
      </div>
    </Dialog>
  )
}
