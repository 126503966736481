import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/auth'
import { useScreenSizeClass } from './utils/media-query'
import Content from './Content'
import { ApolloProvider } from '@apollo/client'
import { ApiClient } from './api/apiClient'
import { LoadPanel } from 'devextreme-react/load-panel'
import { locale } from 'devextreme/localization'
import Welcome from './layout/Welcome'
import { MixpanelProvider } from './contexts/mixpanel'

const AppContent = () => {
  const { user, loading } = useAuth()

  if (loading) {
    return <LoadPanel shadingColor="rgba(0,0,0,0.02)" visible={true} />
  }

  if (user) {
    if (!user.profile.minimum_requirements_met || !user.email_verified) {
      return <Welcome />
    }
    return <Content />
  }

  // If not logged in, default to Drupal login form.
  window.location = '/user/login?destination=' + window.location.pathname
  return <LoadPanel shadingColor="rgba(0,0,0,0.02)" visible={true} />
}

export default function App() {
  locale('en-AU')
  const screenSizeClass = useScreenSizeClass()

  return (
    <BrowserRouter>
      <AuthProvider>
        <MixpanelProvider>
          <ApolloProvider client={ApiClient}>
            <div className={`app text-md text-gray-700 ${screenSizeClass}`}>
              <AppContent />
            </div>
          </ApolloProvider>
        </MixpanelProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
