import React, { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NavLayout from './layout/NavLayout'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import Overlay from './layout/Overlay'

const ArticlePage = lazy(() => import('./pages/articles/ArticlePage'))
const Articles = lazy(() => import('./pages/articles/ArticleListLayout'))
const GovFacts = lazy(() => import('./pages/govfacts/GovFacts'))
const GovFactsLayout = lazy(() => import('./pages/govfacts/GovFactsLayout'))
const Home = lazy(() => import('./pages/home/Home'))
const Publication = lazy(() => import('./pages/publications/PublicationPage'))
const Publications = lazy(() =>
  import('./pages/publications/PublicationListLayout')
)
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage'))
const Tender = lazy(() => import('./pages/tenders/TenderPage'))
const Tenders = lazy(() => import('./pages/tenders/TenderDisplay'))
const Yellowfin = lazy(() => import('./pages/yellowfin/Yellowfin'))
const CommunicationsForm = lazy(() =>
  import('./pages/settings/communications/CommunicationsForm')
)
const SecurityForm = lazy(() =>
  import('./pages/settings/security/SecurityForm')
)
const UserProfileForm = lazy(() =>
  import('./pages/settings/profile/UserProfileForm')
)
const Dashboard = lazy(() => import('./pages/yellowfin/DashboardEmbed'))

export default function Content() {
  // yellowfin.init()

  return (
    <>
      <NavLayout>
        <Suspense
          fallback={
            <div className="absolute inset-0 flex items-center justify-center">
              <LoadIndicator />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articles" element={<Articles />}>
              <Route path=":slug" element={<ArticlePage />} />
            </Route>
            <Route path="/tenders/*" element={<Tenders />}>
              <Route path=":id" element={<Tender />} />
            </Route>
            <Route path="/publications" element={<Publications />}>
              <Route path=":slug" element={<Publication />} />
            </Route>
            <Route path="/tools" element={<Yellowfin />}>
              <Route path=":slug" element={<Dashboard />} />
            </Route>
            <Route path="/govfacts" element={<GovFacts />}>
              <Route path=":slug" element={<GovFactsLayout />} />
            </Route>
            <Route path="/settings/*" element={<SettingsPage />}>
              <Route path="profile" element={<UserProfileForm />} />
              <Route path="security" element={<SecurityForm />} />
              <Route path="communications" element={<CommunicationsForm />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Suspense>

        <Outlet />
      </NavLayout>

      <Overlay backLabel="Back to articles" backPath="/articles"></Overlay>
    </>
  )
}
