import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'
import { getUser, signOut as sendSignOutRequest } from '../api/auth'

const AuthContext = createContext({})
const useAuth = () => useContext(AuthContext)

function AuthProvider(props) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser().then((result) => {
      if (result.isOk) {
        setUser(result.data)
      }

      setLoading(false)
    })
  }, [])

  const signOut = useCallback(async () => {
    sendSignOutRequest()
    await setUser()
  }, [])

  return <AuthContext.Provider value={{ user, signOut, loading }} {...props} />
}

export { AuthProvider, useAuth }
