import { Fragment } from 'react'
import { navigation } from '../app-navigation'
import { Link, useLocation } from 'react-router-dom'

export default function NavItems(props) {
  const location = useLocation()

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const onMenuItemClick = () => {
    if (props.setMobileMenuOpen !== undefined) {
      props.setMobileMenuOpen(false)
    }
  }

  return (
    <Fragment>
      {navigation.map((item, index) => {
        let currentPage = item.path === location.pathname

        // Handle sub-folders.
        if (
          item.path !== '/' &&
          location.pathname !== undefined &&
          location.pathname.split('/')[1] === item.path.split('/')[1]
        ) {
          currentPage = true
        }

        return (
          <Link
            key={index}
            to={item.path}
            className={classNames(
              currentPage
                ? 'bg-violet-700 text-white'
                : 'text-violet-50 hover:bg-violet-700 hover:text-white',
              'group flex items-center rounded-md py-2 px-3 text-sm font-medium md:w-full md:flex-col md:p-3 md:text-xs'
            )}
            aria-current={currentPage ? 'page' : undefined}
            onClick={onMenuItemClick}
          >
            <item.icon
              className={classNames(
                currentPage
                  ? 'text-white'
                  : 'text-violet-200 group-hover:text-white',
                'mr-3 h-6 w-6 md:mr-0'
              )}
              aria-hidden="true"
              stroke="currentColor"
            />
            <span className="md:mt-2">{item.text}</span>
          </Link>
        )
      })}
    </Fragment>
  )
}
