import {
  ApolloClient,
  from,
  HttpLink,
  useMutation,
  useQuery,
} from '@apollo/client'
import { cache } from './cache'
import { onError } from '@apollo/client/link/error'
import { useAuth } from '../contexts/auth'
import toast from '../utils/toast'

const httpLink = new HttpLink({
  uri: '/graphql/isp',
})

const ErrorLink = onError(({ operation, networkError }) => {
  if (networkError !== undefined && networkError.statusCode === 403) {
    operation.getContext().auth.signOut()
  } else {
    toast(
      'Oops, something went wrong! Please refresh the window and contact Intermedium if this issue persists.',
      'error',
      30000
    )
    return 'Network error'
  }
})

export const ApiClient = new ApolloClient({
  link: from([ErrorLink, httpLink]),
  cache: cache,
})

export const useAuthAwareQuery = (query, options = {}) => {
  options.context = { auth: useAuth() }
  return useQuery(query, options)
}

export const useAuthAwareMutation = (mutation, options = {}) => {
  options.context = { auth: useAuth() }
  return useMutation(mutation, options)
}
