import React from 'react'
import { gql } from '@apollo/client'
import { useAuthAwareQuery } from '../../../api/apiClient'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import RequestEmailValidationLink from './RequestEmailValidationLink'

export default function ValidateEmailForm(props) {
  const getProfile = gql`
    query GetUserProfile {
      me {
        id
        email
        email_verified
      }
    }
  `

  const { loading, error, data } = useAuthAwareQuery(getProfile)

  if (loading) {
    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <LoadIndicator />
      </div>
    )
  }

  if (error) {
    return <>{error}</>
  }

  const submitHandler = (e) => {
    e.preventDefault()
  }

  return (
    <div className="max-w-screen-lg">
      <h1 className="font-heading text-5xl text-gray-900">
        Welcome to Intermedium
      </h1>

      <div className="mt-8">
        <p>
          Please verify your email address to activate your Intermedium account.
        </p>
      </div>

      <h2 className="mt-8 text-xl font-medium">Email</h2>
      <p className="mt-2 text-sm text-slate-500">
        Your profile email address is <strong>{data.me.email}</strong>
      </p>

      <form onSubmit={submitHandler} className="mt-8">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h3 className="text-md font-medium text-slate-900">Verification</h3>
            <p className="mt-2 text-sm text-slate-500">
              A welcome email was sent to that address containing a link to
              verify your email address.
            </p>
            <p className="mt-4 text-sm text-slate-500">
              Your email address is yet to be verified, if you need a new link
              you can request one with this button:
            </p>
            <RequestEmailValidationLink />
            <p className="mt-6 text-sm text-slate-500">
              If you have already successfully verified your email in another
              window, please refresh this page.
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
