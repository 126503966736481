import { graphQuery } from './graphQuery'

const fetchUser = async () => {
  const getCurrentUserQuery = `
    query GetCurrentUser {
      me {
        id
        email
        is_staff
        email_verified
        client_name
        package_name
        product_ids
        profile {
          id
          first_name
          job_title
          last_name
          minimum_requirements_met
          organisation
        }
      }
    }
  `
  return graphQuery(getCurrentUserQuery)
    .then((r) => {
      return {
        isOk: true,
        data: r.me,
      }
    })
    .catch(() => {
      return {
        isOk: false,
      }
    })
}

export async function signOut() {
  return fetch('/user/logout', {
    method: 'GET',
    cache: 'no-store',
    mode: 'same-origin',
    redirect: 'manual',
  })
    .then(() => {
      return {
        isOk: true,
      }
    })
    .catch(() => {
      return {
        isOk: false,
        message: 'Log out failed',
      }
    })
}

export async function getUser() {
  try {
    return fetchUser().then((r) => {
      return r
    })
  } catch {
    return {
      isOk: false,
    }
  }
}
