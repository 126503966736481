import React, { useEffect, useState } from 'react'
import { gql, useReactiveVar } from '@apollo/client'
import { useAuthAwareQuery } from '../../../api/apiClient'
import { RadioGroup, Switch } from '@headlessui/react'
import { RequiredRule, Validator } from 'devextreme-react/validator'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { classNames } from '../../../utils/headlessUiHelpers'
import { profileEditValuesVar } from './UserProfileForm'

export default function IndustryJobFunction(props) {
  const [validationFailed, setValidationFailed] = useState('')
  const [selected, setSelected] = useState()
  const profileEditValues = useReactiveVar(profileEditValuesVar)
  const [showDescriptions, setShowDescriptions] = useState(false)

  useEffect(() => {
    setSelected(props.jobFunction)
  }, [props.jobFunction])

  const getIndustryJobFunctions = gql`
    query GetIndustryJobFunctions {
      industry_job_functions {
        items {
          id
          name
          description
        }
      }
    }
  `
  const { loading, error, data } = useAuthAwareQuery(getIndustryJobFunctions)

  if (loading) {
    return (
      <div className="flex justify-center py-32 sm:col-span-6">
        <div className="relative">
          <LoadIndicator />
        </div>
      </div>
    )
  }

  if (error) {
    // @todo: handle errors
  }

  const jobFunctionValidationAdapter = {
    getValue: () => {
      return selected
    },
    applyValidationResults: (e) => {
      setValidationFailed(!e.isValid)
    },
  }

  const jobFunctions = data.industry_job_functions.items

  const onChange = (v) => {
    setSelected(v)
    profileEditValuesVar({ ...profileEditValues, job_function: v })
  }

  return (
    <div className="sm:col-span-6">
      <RadioGroup value={selected} onChange={onChange}>
        <div className="items-center justify-between sm:flex">
          <RadioGroup.Label className="mb-1 block text-sm font-medium text-slate-900">
            My work is best described as *
          </RadioGroup.Label>

          <Switch.Group
            as="div"
            className="mt-1 mb-2 flex items-center sm:flex-row-reverse"
          >
            <Switch
              checked={showDescriptions}
              onChange={setShowDescriptions}
              className={classNames(
                showDescriptions ? 'bg-teal-300' : 'bg-gray-200',
                'relative inline-flex h-5 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showDescriptions ? 'translate-x-3' : 'translate-x-0',
                  'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
            <Switch.Label as="span" className="mx-3">
              <span className="text-sm font-medium text-gray-500">
                Detailed descriptions
              </span>
            </Switch.Label>
          </Switch.Group>
        </div>

        <Validator adapter={jobFunctionValidationAdapter}>
          <RequiredRule message="Work description is required" />
        </Validator>

        <div className="-space-y-px rounded-md bg-white">
          {jobFunctions.map((jobFunction, index) => (
            <RadioGroup.Option
              key={jobFunction.id}
              value={jobFunction.id}
              className={({ checked }) =>
                classNames(
                  index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  index === jobFunctions.length - 1
                    ? 'rounded-bl-md rounded-br-md'
                    : '',
                  checked
                    ? 'z-10 border-teal-300 bg-teal-50'
                    : 'border-gray-200',
                  'relative flex cursor-pointer border px-4 py-3 focus:outline-none',
                  validationFailed ? 'border-red-300' : ''
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? 'border-transparent bg-teal-300'
                        : 'border-gray-300 bg-white',
                      active ? 'ring-2 ring-teal-300 ring-offset-2' : '',
                      'mt-0.5 flex h-4 w-4 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border'
                    )}
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? 'text-teal-900' : 'text-gray-900',
                        'block text-sm font-medium'
                      )}
                    >
                      {jobFunction.name}
                    </RadioGroup.Label>

                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        showDescriptions ? 'block' : 'hidden',
                        checked ? 'text-teal-700' : 'text-gray-500',
                        'block text-sm'
                      )}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: jobFunction.description,
                        }}
                      />
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}
