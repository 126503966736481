import { ReactComponent as CashlessPaymentEBankingBrowser } from './icons/streamline/cashless-payment-e-banking-browser.svg'
import { ReactComponent as CogDouble2 } from './icons/streamline/cog-double-2.svg'
import { ReactComponent as DataFileBarsSearch } from './icons/streamline/data-file-bars-search.svg'
import { ReactComponent as House } from './icons/streamline/house.svg'
import { ReactComponent as Newspaper } from './icons/streamline/newspaper.svg'
import { ReactComponent as PerformanceTabletIncrease } from './icons/streamline/performance-table-increase.svg'
import { ReactComponent as TaskListQuestion } from './icons/streamline/task-list-question.svg'
// import { ReactComponent as VintageTv3 } from './icons/streamline/vintage-tv-3.svg'

export const navigation = [
  {
    text: 'Home',
    path: '/',
    icon: House,
  },
  {
    text: 'Articles',
    path: '/articles',
    icon: Newspaper,
  },
  {
    text: 'Tenders',
    path: '/tenders',
    icon: TaskListQuestion,
  },
  {
    text: 'Publications',
    path: '/publications',
    icon: PerformanceTabletIncrease,
  },
  {
    text: 'Tools',
    path: '/tools',
    icon: DataFileBarsSearch,
  },
  // {
  //   text: 'Videos',
  //   path: '/videos',
  //   icon: VintageTv3,
  // },
  {
    text: 'GovFacts',
    path: '/govfacts',
    icon: CashlessPaymentEBankingBrowser,
  },
  {
    text: 'Settings',
    path: '/settings',
    icon: CogDouble2,
  },
]
