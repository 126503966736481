import { useState, useCallback, useEffect } from 'react'

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize())
  const onSizeChanged = useCallback(() => {
    setScreenSize(getScreenSize())
  }, [])

  useEffect(() => {
    subscribe(onSizeChanged)

    return () => {
      unsubscribe(onSizeChanged)
    }
  }, [onSizeChanged])

  return screenSize
}

export const useScreenSizeClass = () => {
  const screenSize = useScreenSize()

  if (screenSize.isXXLarge) {
    return 'screen-xxlarge'
  }

  if (screenSize.isXLarge) {
    return 'screen-xlarge'
  }

  if (screenSize.isLarge) {
    return 'screen-large'
  }

  if (screenSize.isMedium) {
    return 'screen-medium'
  }

  return 'screen-small'
}

let handlers = []
const smallMedia = window.matchMedia('(max-width: 639.99px)')
const mediumMedia = window.matchMedia(
  '(min-width: 640px) and (max-width: 767.99px)'
)
const largeMedia = window.matchMedia(
  '(min-width: 768px) and (max-width: 1023.99px)'
)
const xLargeMedia = window.matchMedia(
  '(min-width: 1024px) and (max-width: 1279.99px)'
)
const xxLargeMedia = window.matchMedia('(min-width: 1280px)')

;[smallMedia, mediumMedia, largeMedia, xLargeMedia, xxLargeMedia].forEach(
  (media) => {
    media.addListener((e) => {
      e.matches && handlers.forEach((handler) => handler())
    })
  }
)

const subscribe = (handler) => handlers.push(handler)

const unsubscribe = (handler) => {
  handlers = handlers.filter((item) => item !== handler)
}

function getScreenSize() {
  return {
    isSmall: smallMedia.matches,
    isMedium: mediumMedia.matches,
    isLarge: largeMedia.matches,
    isXLarge: xLargeMedia.matches,
    isXXLarge: xxLargeMedia.matches,
  }
}
