import React, { useEffect, useState } from 'react'
import { Lookup } from 'devextreme-react/lookup'
import AddressCountrySource from './AddressCountrySource'
import AddressSubdivisionSource from './AddressSubdivisionSource'
import { useReactiveVar } from '@apollo/client'
import { profileEditValuesVar } from './UserProfileForm'

export default function ProfileCountryWidget(props) {
  const [country, setCountry] = useState()
  const [adminArea, setAdminArea] = useState()

  const [adminAreaLabel, setAdminAreaLabel] = useState('')
  const profileEditValues = useReactiveVar(profileEditValuesVar)

  const countries = AddressCountrySource()
  const states = AddressSubdivisionSource({ country_code: country })

  useEffect(() => {
    if (props.location) {
      setCountry(props.location.country_code)
      setAdminArea(props.location.administrative_area)
    }
  }, [props.location])

  useEffect(() => {
    if (country) {
      countries.byKey(country).then((r) => {
        setAdminAreaLabel(r.administrative_area_type)
      })
    }
  }, [countries, country])

  const onCountryChanged = (e) => {
    if (e.value !== country) {
      profileEditValuesVar({
        ...profileEditValues,
        location: {
          country_code: e.value,
        },
      })
    }
    setCountry(e.value)
  }

  const onAdminAreaChanged = (e) => {
    if (e.value !== props.location.administrative_area) {
      profileEditValuesVar({
        ...profileEditValues,
        location: {
          country_code: country,
          administrative_area: e.value,
        },
      })
    }
    setAdminArea(e.value)
  }

  return (
    <>
      <div className="sm:col-span-3 sm:col-start-1">
        <div className="relative rounded-md bg-white">
          <Lookup
            dataSource={countries}
            displayExpr="name"
            label="Country"
            name="country"
            onValueChanged={onCountryChanged}
            searchEnabled={true}
            showClearButton={true}
            stylingMode="outlined"
            value={country}
            valueExpr="country_code"
          />
        </div>
      </div>

      {adminAreaLabel && (
        <div className="sm:col-span-3">
          <div className="relative rounded-md bg-white">
            <Lookup
              dataSource={states}
              displayExpr="name"
              label="State"
              name="state"
              onValueChanged={onAdminAreaChanged}
              searchEnabled={true}
              showClearButton={true}
              stylingMode="outlined"
              value={adminArea}
              valueExpr="code"
            />
          </div>
        </div>
      )}
    </>
  )
}
