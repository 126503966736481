import React, { useRef, useState } from 'react'
import { gql } from '@apollo/client'
import { useAuthAwareMutation } from '../../../api/apiClient'
import TextBox from 'devextreme-react/text-box'
import { EmailRule, RequiredRule, Validator } from 'devextreme-react/validator'
import ValidationGroup from 'devextreme-react/validation-group'
import toast from '../../../utils/toast'

export default function RequestEmailChange() {
  const emailChangeValidationGroup = useRef()

  const [newEmail, setNewEmail] = useState('')

  const requestEmailChangeMutation = gql`
    mutation RequestEmailChangeMutation($new_email: String!) {
      requestEmailChange(new_email: $new_email) {
        errors
      }
    }
  `

  const [emailChangeMutation, { loading }] = useAuthAwareMutation(
    requestEmailChangeMutation
  )

  const requestEmailChange = () => {
    const result = validateForm()

    if (result.isValid) {
      emailChangeMutation({
        variables: { new_email: newEmail },
      }).then((r) => {
        if (r.error || r.data.errors) {
          // @todo: handle errors
          return
        }

        toast('Email change request has been sent', 'success')
      })
    }
  }

  const validateForm = () => {
    return emailChangeValidationGroup.current.instance.validate()
  }

  const submitHandler = (e) => {
    e.preventDefault()
  }

  return (
    <form
      onSubmit={submitHandler}
      className="mt-6 grid grid-cols-1 gap-y-8 sm:grid-cols-6 sm:gap-x-6"
    >
      <div className="sm:col-span-3">
        <ValidationGroup ref={emailChangeValidationGroup}>
          <div className="relative rounded-md bg-white">
            <TextBox
              className="mt-1"
              label="New email address"
              mode="email"
              name="email-address"
              onValueChanged={(d) => {
                setNewEmail(d.value)
              }}
              placeholder="New email address"
              stylingMode="outlined"
              value={newEmail}
            >
              <Validator>
                <RequiredRule />
                <EmailRule />
              </Validator>
            </TextBox>
          </div>
        </ValidationGroup>
      </div>

      <div className="sm:col-span-3">
        <button
          className={`${
            loading
              ? 'disabled bg-gray-300 hover:bg-gray-500'
              : 'bg-teal-300 hover:bg-teal-500'
          } mt-1 inline-flex justify-center self-end rounded-md border border-transparent py-2 px-4 text-sm font-bold text-white shadow-sm focus:outline-none  focus:ring-2 focus:ring-teal-300 focus:ring-offset-2 sm:self-start`}
          disabled={loading}
          onClick={requestEmailChange}
        >
          Request change
        </button>
      </div>
    </form>
  )
}
